/* IMPORT CUSTOM FONT */
/* @import url('https://fonts.googleapis.com/css?family=Inter:100,300,400,500,700&display=swap'); */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Inter', sans-serif;
  }
}

body {
  background-color: #000;
}

/* Truncate 2 lines utility */
.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* line-height: 1.3;
  height: 2.1rem; */
}

.bottom-45 {
  bottom: 45px;
}

.origin-bottm-right {
  transform-origin: bottom right;
}

.origin-top-right {
  transform-origin: top right;
}
